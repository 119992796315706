.slick-slide {
  padding: 0 10px;
  height: inherit !important;
}

.slick-slide > div {
  height: 100%;
}

@media (min-width: 640px) {
  .slick-slide {
    padding: 0 60px;
  }
}

.slick-track {
  display: flex !important;
}

.slick-dots {
  bottom: -40px !important;
}

.slick-dots li {
  height: 4px !important;
  width: 32px !important;
  margin: 0 !important;
  background-color: white;
}

.slick-dots li.slick-active {
  background-color: gray;
}
