@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Campton';
  src: local('Campton'),
    url(./fonts/campton/CamptonLight.otf) format('opentype');
  font-weight: 300;
}

@font-face {
  font-family: 'Campton';
  src: local('Campton'),
    url(./fonts/campton/CamptonMedium.otf) format('opentype');
  font-weight: 500;
}

@font-face {
  font-family: 'AvenirBlack';
  src: local('AvenirBlack'),
    url(./fonts/avenir/AvenirBlack.ttf) format('truetype');
  font-weight: 900;
}

@font-face {
  font-family: 'LexendDeca';
  src: local('LexendDeca'),
    url(./fonts/lexendDeca/LexendDecaLight.ttf) format('truetype');
  font-weight: 300;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer components {
  .content {
    @apply max-w-screen-2xl m-auto;
  }
}
